import React, { useMemo } from "react";
import { useSidebar, useHubTheme } from "admin-portal-shared-services";
import {
  Checklist1,
  Crosshair,
  PhoneOff,
  UserSettings,
  ChartTree,
} from "@admin-portal-shared-components/icons";
import { useLocation, useHistory } from "react-router-dom";
import { MapPin, Target, Users } from "@hexa-ui/icons";
import { GROW_ORGANIZATION_ACTIVATION } from "@config/featureToggles";
import { isFeatureEnabled } from "grow-shared-services";
import useSideBar from "../../../hooks/sideBar/useSideBar";
import { formatMessage, updateIntlProvider } from "../../../i18n/formatters";
import { BASE_NAME } from "../../../config/constants";
import { sidebarInteraction } from "../../../config/typewriter";
import {
  getUserInfos,
  handleNavigationEvent,
} from "../../../config/utils/functions";

/* istanbul ignore next */
const menuIcons = {
  location: () => <MapPin />,
  campaigns: () => <Crosshair />,
  userSettings: () => <UserSettings />,
  blockList: () => <PhoneOff />,
  missions: () => <Checklist1 />,
  teamStructuring: () => <ChartTree />,
  globalMissionsIcon: () => <Target />,
  teamManagementIcon: () => <Users />,
};

/* istanbul ignore next */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SideBarAdmin = () => {
  const {
    isTeamStructureEnabled,
    isMissionsManagementEnabled,
    isGrowAdminLocalMissionMenuEnabled,
    isDisableB2oCampaign,
    isDisableFocusSKUCampaign,
    isGrowAdminBetweenMissionManagement,
    isGrowOrganizationActivation,
    hasBeesGrowAll,
    hasAgentWritePermission,
    hasCampaignWritePermission,
    hasBlockListWritePermission,
    hasTeamStructureWritePermission,
    hasTeamStructureReadPermission,
    hasMissionManagementWrite,
    hasTeamsOrganizationPermission,
  } = useSideBar();
  const location = useLocation();
  const history = useHistory();

  const isGrowManager =
    hasBeesGrowAll ||
    (hasCampaignWritePermission &&
      hasAgentWritePermission &&
      hasBlockListWritePermission &&
      hasMissionManagementWrite &&
      hasTeamStructureWritePermission);
  const isCoordinator =
    hasAgentWritePermission &&
    hasCampaignWritePermission &&
    hasTeamStructureReadPermission;
  const isSolutions = hasBlockListWritePermission;

  /* istanbul ignore next */
  const memoListMenuWithAccess = useMemo(() => {
    const { preferredLanguage } = getUserInfos();

    const isGrowOrganizationActivationEnabled = isFeatureEnabled(
      GROW_ORGANIZATION_ACTIVATION,
    );

    updateIntlProvider(preferredLanguage);

    const handleAnalytics = (buttonLabel: string, buttonName: string) => {
      sidebarInteraction({
        button_label: buttonLabel,
        button_name: buttonName,
        button_selection: null,
      });
    };

    const handleChangeConfirmationModal = (
      fromButtonName: string,
      buttonName: string,
      fromURI: string,
      url: string,
      event: React.SyntheticEvent,
    ) => {
      handleNavigationEvent({
        fromButtonName,
        toButtonName: buttonName,
        uri: location.pathname,
        fromURI: `${BASE_NAME}/${fromURI}`,
        toURI: url,
        event,
        navigate: () => history.push(url),
      });
    };

    const handleCallConfirmationModal = (
      buttonName: string,
      url: string,
      event?: React.SyntheticEvent,
    ) => {
      handleChangeConfirmationModal(
        "missions-management",
        buttonName,
        `management`,
        url,
        event as React.SyntheticEvent,
      );

      handleChangeConfirmationModal(
        "missions-management",
        buttonName,
        `local-missions/new-sponsored-mission`,
        url,
        event as React.SyntheticEvent,
      );

      handleChangeConfirmationModal(
        "global-missions",
        buttonName,
        "global-missions",
        url,
        event as React.SyntheticEvent,
      );
    };

    return [
      {
        id: "teams",
        title: formatMessage({ id: "TEAM_MANAGEMENT.TITLE" }),
        path: `${BASE_NAME}/teams`,
        icon: menuIcons.teamManagementIcon,
        hasAccess:
          isGrowOrganizationActivation && hasTeamsOrganizationPermission,
        onPageChange: (event?: React.SyntheticEvent) => {
          handleAnalytics(
            formatMessage({ id: "TEAM_MANAGEMENT.TITLE" }),
            "teams",
          );

          handleCallConfirmationModal(
            "teams",
            `${BASE_NAME}/teams`,
            event as React.SyntheticEvent,
          );
        },
      },
      {
        id: "missions-management",
        title: formatMessage({ id: "MISSIONS_MANAGEMENT.TITLE" }),
        path: `${BASE_NAME}/management`,
        icon: menuIcons.missions,
        hasAccess:
          isGrowManager &&
          isMissionsManagementEnabled &&
          !isGrowAdminBetweenMissionManagement,
        onPageChange: (event?: React.SyntheticEvent) => {
          handleAnalytics(
            formatMessage({ id: "MISSIONS_MANAGEMENT.TITLE" }),
            "missions-management",
          );

          handleCallConfirmationModal(
            "missions-management",
            `${BASE_NAME}/management`,
            event as React.SyntheticEvent,
          );
        },
      },
      {
        id: "global-missions",
        title: isGrowOrganizationActivationEnabled
          ? formatMessage({ id: "GLOBAL_MISSIONS.TITLE" })
          : formatMessage({ id: "MISSIONS_MANAGEMENT.TITLE" }),
        path: `${BASE_NAME}/global-missions`,
        icon: menuIcons.missions,
        hasAccess: isGrowAdminBetweenMissionManagement,
        onPageChange: (event?: React.SyntheticEvent) => {
          handleAnalytics(
            formatMessage({ id: "MISSIONS_MANAGEMENT.TITLE" }),
            "global-missions",
          );

          handleCallConfirmationModal(
            "global-missions",
            `${BASE_NAME}/global-missions`,
            event as React.SyntheticEvent,
          );
        },
      },
      {
        id: "local-missions",
        title: formatMessage({ id: "LOCAL_MISSIONS.TITLE" }),
        path: `${BASE_NAME}/local-missions`,
        icon: menuIcons.location,
        hasAccess: isGrowManager && isGrowAdminLocalMissionMenuEnabled,
        onPageChange: (event?: React.SyntheticEvent) => {
          handleAnalytics(
            formatMessage({ id: "LOCAL_MISSIONS.TITLE" }),
            "local-missions",
          );

          handleCallConfirmationModal(
            "local-missions",
            `${BASE_NAME}/local-missions`,
            event as React.SyntheticEvent,
          );
        },
      },
      {
        id: "campaigns",
        title: formatMessage({ id: "CAMPAIGNS.CAMPAIGNS_TITLE" }),
        path: `${BASE_NAME}/campaigns`,
        icon: menuIcons.campaigns,
        hasAccess:
          (isGrowManager || isCoordinator) &&
          !(
            (isDisableB2oCampaign || isGrowAdminLocalMissionMenuEnabled) &&
            isDisableFocusSKUCampaign
          ),
        onPageChange: (event?: React.SyntheticEvent) => {
          handleAnalytics(
            formatMessage({ id: "CAMPAIGNS.CAMPAIGNS_TITLE" }),
            "campaigns",
          );
          handleCallConfirmationModal(
            "campaigns",
            `${BASE_NAME}/campaigns`,
            event as React.SyntheticEvent,
          );
        },
      },
      {
        id: "block-list",
        title: formatMessage({ id: "BLOCK_LIST.MODAL_TITLE" }),
        path: `${BASE_NAME}/block-list`,
        icon: menuIcons.blockList,
        hasAccess: isGrowManager || isSolutions,
        onPageChange: (event?: React.SyntheticEvent) => {
          handleAnalytics(
            formatMessage({ id: "BLOCK_LIST.MODAL_TITLE" }),
            "block-list",
          );
          handleCallConfirmationModal(
            "campaigns",
            `${BASE_NAME}/campaigns`,
            event as React.SyntheticEvent,
          );
        },
      },
      {
        id: "agent-setup",
        title: formatMessage({ id: "AGENT_SEGMENTATION.MODAL_TITLE" }),
        path: `${BASE_NAME}/agent`,
        icon: menuIcons.userSettings,
        hasAccess:
          (isGrowManager || isCoordinator) && !isGrowOrganizationActivation,
        onPageChange: (event?: React.SyntheticEvent) => {
          handleAnalytics(
            formatMessage({ id: "AGENT_SEGMENTATION.MODAL_TITLE" }),
            "agent-setup",
          );

          handleCallConfirmationModal(
            "agent-setup",
            `${BASE_NAME}/agent`,
            event as React.SyntheticEvent,
          );
        },
      },
      {
        id: "team-structure",
        title: formatMessage({ id: "TEAM_STRUCTURE.TITLE" }),
        path: `${BASE_NAME}/team-structure`,
        icon: menuIcons.teamStructuring,
        hasAccess: (isGrowManager || isCoordinator) && isTeamStructureEnabled,
        onPageChange: (event?: React.SyntheticEvent) => {
          handleAnalytics(
            formatMessage({ id: "TEAM_STRUCTURE.TITLE" }),
            "team-structure",
          );
          handleCallConfirmationModal(
            "team-structure",
            `${BASE_NAME}/team-structure`,
            event as React.SyntheticEvent,
          );
        },
      },
    ].filter((listItem) => listItem.hasAccess);
  }, [
    isGrowOrganizationActivation,
    hasTeamsOrganizationPermission,
    isGrowManager,
    isMissionsManagementEnabled,
    isGrowAdminBetweenMissionManagement,
    isGrowAdminLocalMissionMenuEnabled,
    isCoordinator,
    isDisableB2oCampaign,
    isDisableFocusSKUCampaign,
    isSolutions,
    isTeamStructureEnabled,
    location.pathname,
    history,
  ]);

  const memoSidebarParams = useMemo(
    () => ({
      items: memoListMenuWithAccess,
      utils: [],
    }),
    [memoListMenuWithAccess],
  );

  useHubTheme("grow");
  useSidebar(memoSidebarParams);
  return null;
};

export default SideBarAdmin;
